import OpenSeadragon from 'openseadragon';

document.addEventListener("DOMContentLoaded", () => {
    if(document.getElementById("openseadragon1")) {
        console.log("Intialize image zoom plugin");
        var viewer = OpenSeadragon({
            id: "openseadragon1",
            prefixUrl: "/static/openseadragon/images/",
            tileSources:   [
                "/static/openseadragon/wall-of-hope/output.dzi"
            ]
        });
    }
});