import Swiper, {Navigation, Pagination} from 'swiper';
import {debounce} from './utils';

Swiper.use([Navigation, Pagination]);

const defautlCarouselConfig = {
    slidesPerView: 1,
    spaceBetween: 20,
    breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        1200: {
            slidesPerView: 3,
            spaceBetween: 40
        }
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    }
};

export default class BTCarousel {
    constructor(carousel) {
        this.swiperInstance = undefined;
        this.carousel = carousel;
        this.carouselConfig = defautlCarouselConfig;
        this.carouselElement = `[js-carousel-idx='${carousel.getAttribute("js-carousel-idx")}']`;
        this.scrollHandlerFun = debounce(this.scrollHandler.bind(this), 10);
    }
    
    scrollHandler() {
        let carouselDimension = document.getElementById('the-first-team').getBoundingClientRect();
        if(carouselDimension.top < carouselDimension.height / 2) {
            this.carousel.classList.add('apply-animation');
            window.removeEventListener("scroll", this.scrollHandlerFun);
        }
    }

    init() {
        this.swiperInstance = new Swiper(this.carouselElement, this.carouselConfig);
        window.swiperInstance = this.swiperInstance;
        window.swiperInstance.navigationIndex = 1;
        let slides = this.swiperInstance.slides;
        for (let index = 0; index < slides.length; index++) {
            let slide = slides[index];
            const innerChildren = slide.children[0];
            innerChildren.setAttribute('data-index', index)
        }
        window.addEventListener("scroll", this.scrollHandlerFun);
    }
}

window.addEventListener('DOMContentLoaded', (event) => {
    let carousels = document.querySelectorAll('.bt-carousel');
    carousels.forEach((carousel, idx) => {
        carousel.setAttribute("js-carousel-idx", idx);
        new BTCarousel(carousel).init();
    });
});