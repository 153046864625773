import ConsentManager from './consent-manager';
import bindAnalyticEvents from './analytics';

// This class will be responsible to fetch the player data
// and render it on the ajax overlay also all other related stuff
export default class OverlayDataHandler {
    constructor() {
        this.data = null;
        this.apiURL = null;
        this.renderMethod = null;
        this.htmlWrapper = document.querySelector('.dynamic-overlay .overlay-body')
    }

    setData(apiURL, renderMethod) {
        this.apiURL = apiURL;
        this.renderMethod = renderMethod;
    }

    async renderData() {
        if(this.apiURL) {
            let data = await fetch(this.apiURL).then(response => response.json());
            this.data = data;
            this.choseRenderer();
            bindAnalyticEvents();
        }
    }

    clearOveralyData() {
        this.htmlWrapper.innerHTML = '';
    }

    choseRenderer() {
        if(this.renderMethod == "PlayerProfile") {
            this.renderPlayerProfile();
            return;
        }
        if(this.renderMethod == "TechTips") {
            this.renderTechTips();
            return;
        }
        if(this.renderMethod == "HeroVideos") {
            this.renderHeroVideos();
            return;
        }
    }
    // Overlay slider navigation
    setOverlayNavigationRenderer(navigationIndex, flag) {
        const totalSlideLength = window.swiperInstance.slides.length;
        if (navigationIndex > totalSlideLength) {
            window.swiperInstance.navigationIndex = totalSlideLength - 1;
            return;
        } else if (navigationIndex < 1) {
            window.swiperInstance.navigationIndex = 1;
            return;
        }

        let currentIndex = window.swiperInstance.activeIndex;
        if (currentIndex === 0) {
            window.swiperInstance.slideNext();
        }
        let currentSlide = window.swiperInstance.slides[navigationIndex];
        const active = currentSlide ? currentSlide.children[0].getAttribute('data-url') : null;
        this.setData(active, 'PlayerProfile');
        this.renderData();

        window.swiperInstance.navigationIndex = navigationIndex;
        if (flag) {
            window.swiperInstance.slideNext();
        } else {
            window.swiperInstance.slidePrev();
        }
    }

    renderPlayerProfile() {
        let data = this.data;
        let hasCarouselIndex = this.data.overlayCarouselIndex ? this.data.overlayCarouselIndex : '';
        this.htmlWrapper.innerHTML= `
        <div class="overlay-player-profile">

            <div class="c-grid-row">
                <div class="c-col-sm c-col-m-6 left-container">
                    <!-- <picture>
                     ${(() => {
                        let srcMarkup = '';
                        data.playerimagesrcset.forEach((item, index) => {
                          srcMarkup += `<source media="${item.mquery}" srcset="${item.image}">`;
                        });
                        return srcMarkup;
                      })()}
                       <img class="player-image-main" src="${data.playerimagesrc}" alt="${data.palyerimagealt}">
                    </picture> -->
                    <div class="image-container" style="background-image: url(${data.playerimagesrcset[0].image})">
                        <img class="player-image-main" src="${data.playerimagesrc}" alt="${data.palyerimagealt}">
                    </div>
                    <div class="${data.role == '' ? 'player-info' : 'staff-info'}">
                        <h2 class="player-number">${data.playernumber}</h2>
                        <span class="role">${data.role}</span>
                    </div>
                    <img class="country-badge" src="${data.countrybadgeimage}" height="64" width="64" alt="${data.countrybadgeimagealt}" />
                </div>
                <div class="c-col-sm c-col-m-6">
                    <div class="player-detail">
                        <h3 style="text-transform: uppercase;">${data.firstname}</h3>
                        <h2>${data.lastname}</h2>
                        <hr></hr>
                        <div class="slider-btn">
                            <a href="#" class="s-prev ${hasCarouselIndex == 'first' ? 'arrow-disabled' : ''}"><img class="icon-disabled" src="/static/images/Left_Inactive.svg" /><img src="/static/images/arrow-left.png" class="default-icon" alt="Previous Player" /><img class="hover-icon" src="/static/images/hover-arrow-left.svg" alt="Previous Player" /></a>
                            <span>Explore the players</span>
                            <a href="#" class="s-next ${hasCarouselIndex == 'last' ? 'arrow-disabled' : ''}"><img class="icon-disabled" src="/static/images/Right_Inactive.svg" alt="Next Player" /><img src="/static/images/arrow-right.png" class="default-icon" /><img class="hover-icon" src="/static/images/hover-arrow-right.svg" alt="Next Player" /></a>
                        </div>
                        <p class="intro">${data.playerinfo}</p>
                        <span>${data.header}</span>
                        <p class="quote"><i class="start-quote">“</i>${data.playerquote}<i class="end-quote">”</i></p>

                    </div>
                </div>
            </div>
	    </div>`;

        for (let btn of document.querySelectorAll('.s-next')) {
            btn.addEventListener('click', (e) => {
                e.preventDefault();
                let currentNavigationIndex = window.swiperInstance.navigationIndex;
                this.setOverlayNavigationRenderer(currentNavigationIndex+1, true);
            });
        }

        for (let btn of document.querySelectorAll('.s-prev')) {
            btn.addEventListener('click', (e) => {
                e.preventDefault();
                let currentNavigationIndex = window.swiperInstance.navigationIndex;
                this.setOverlayNavigationRenderer(currentNavigationIndex-1, false);
            });
        }
    }

    renderTechTips() {
        let userConsent = ConsentManager.getConsentCookie();
        let shareDomain = window.location.origin;
        let data = this.data;
        this.htmlWrapper.innerHTML= `
        <div class="overlay-tech-tips max-width-container c-grid-row">
            <!--<div class="overlay-tech-tips-container">-->
              <div class="c-col-sm c-col-tb c-col-m-8">
                <div class="video">
                    <iframe id="${data.id}" type="text/html"
                    ${(() => {
                    let srcmarkup = '';
                    srcmarkup = `
                    ${userConsent == 'accept' ? `src="${data.youtubevideo}"` : `src="${data.youtubevideo_no_consent}"`}
                    `;
                    return srcmarkup;
                    })()}
                    frameborder="0" allowfullscreen>
                    </iframe>
                    <div class="video-transcript">
                        <p><a target="_blank" href="${data.transcript_link}">${data.transcript} | <span>${data.transcript_size}</span></a></p>
                    </div>
                </div>

                <div class="further-reading m-hide tb-hide d-show">
                    <h2>${data.furtherreading_title}</h2>
                    ${(() => {
                        let markup = '';
                        let itemLength = data.furtherreading.length;

                        data.furtherreading.forEach((item, index) => {
                            let analyticsMarkup = item.analytics ? `data-analytics='${item.analytics}'` : '';
                            markup += `
                            ${index % 3 == 0 ? index == 0 ? `<div class="c-grid-row">` : `</div><div class="c-grid-row">` : ''}
                            <div class="left-border c-grid-col c-col-sm c-col-m-4">
                                <a href="${item.link}" target="_blank" class="further-reading-item" ${analyticsMarkup}>
                                <h3 class="color-yellow">${item.title}</h3>
                                <p>${item.description}</p>
                                <span class="icon-newtab"></span>
                                </a>
                            </div>
                            ${index == itemLength - 1 ? `</div>` : ''}`;
                        });
                        return markup;
                    })()}
                </div>

                <div class="tech-tips d-hide m-show tb-show">
                    ${data.hideTechTips ? '' : '<img src="/static/images/overlay/tech-tips-logo.svg" width="158" height="45" alt="BT tech tips" />'}
                    <h2 class="color-pink">${data.headline}</h2>
                    ${data.sublineLink ? `<a href="${data.sublineLink}" data-analytics='${data.analytics}' target="_blank">${data.subline}</a>` : `<p>${data.subline}</p>`}

                    <h3>${data.listheader}</h3>
                    <ul>
                        ${data.learntlist.map((item) => {
                            return `<li><span class="tip-text">${item.text}</span></li>`
                        }).join('')}
                    </ul>
                </div>

              </div>
              <div class="c-col-sm c-col-tb c-col-m-4">
                <div class="tech-tips m-hide tb-hide d-show">
                    ${data.hideTechTips ? '' : '<img src="/static/images/overlay/tech-tips-logo.svg" width="158" height="45" alt="BT tech tips" />'}
                    <h2 class="color-pink">${data.headline}</h2>
                    ${data.sublineLink ? `<a href="${data.sublineLink}" data-analytics='${data.analytics}' target="_blank">${data.subline}</a>` : `<p>${data.subline}</p>`}

                    <h3>${data.listheader}</h3>
                    <ul>
                        ${data.learntlist.map((item) => {
                            return `<li><span class="tip-text">${item.text}</span></li>`
                        }).join('')}
                    </ul>
                </div>

                <div class="further-reading d-hide m-show tb-show">
                    <h2>${data.furtherreading_title}</h2>
                    ${(() => {
                        let markup = '';
                        let itemLength = data.furtherreading.length;

                        data.furtherreading.forEach((item, index) => {
                            let analyticsMarkup = item.analytics ? `data-analytics='${item.analytics}'` : '';
                            markup += `
                            ${index % 3 == 0 ? index == 0 ? `<div class="c-grid-row">` : `</div><div class="c-grid-row">` : ''}
                            <div class="left-border c-grid-col c-col-sm c-col-m-4">
                                <a href="${item.link}" target="_blank" class="further-reading-item" ${analyticsMarkup}>
                                <h3 class="color-yellow">${item.title}</h3>
                                <p>${item.description}</p>
                                <span class="icon-newtab"></span>
                                </a>
                            </div>
                            ${index == itemLength - 1 ? `</div>` : ''}`;
                        });
                        return markup;
                    })()}
                </div>

                <div class="social-share-wrapper">
                    <p>Share</p>
                    <a href="https://twitter.com/intent/tweet?url=${shareDomain}/&text=I%20support%20Hope%20United&hashtags=HopeUnited" class="icon icon-twittter" target="_blank">
                            <span class="sr-only">Share on Twitter</span>
                    </a>
                    <a href="https://www.facebook.com/sharer/sharer.php?u=${shareDomain}%2F&amp;src=sdkpreparse" class="icon icon-facebook" target="_blank">
                            <span class="sr-only">Share on Facebook</span>
                    </a>
                </div>
              </div>
            <!--</div>-->
	    </div>`;
    }

    renderHeroVideos() {
        let userConsent = ConsentManager.getConsentCookie();
        let shareDomain = window.location.origin;
        let data = this.data;
        this.htmlWrapper.innerHTML= `
        <div class="overlay-hero-video max-width-container c-grid-row">
            <div class="c-col-sm c-col-tb c-col-m-8">
                <div class="video">
                <div class="video-iframe-wrapper">
                        <iframe id="${data.id}" type="text/html"
                        ${(() => {
                        let srcmarkup = '';
                        srcmarkup = `
                        ${userConsent == 'accept' ? `src="${data.youtubevideo}"` : `src="${data.youtubevideo_no_consent}"`}
                        `;
                        return srcmarkup;
                        })()}
                                frameborder="0" allowfullscreen>
                            </iframe>
                    </div>
                    <div class="video-transcript">
                        <p><a target="_blank" href="${data.transcript_link}">${data.transcript} | <span>${data.transcript_size}</span></a></p>
                    </div>
                </div>
            </div>
            <div class="c-col-sm c-col-tb c-col-m-4 ">
                <div class="hero-video-content">
                <h2 class="color-pink">${data.title}</h2>
                <p>${data.descParaOne}</p>
                <p>${data.descParaTwo}</p>
                </div>
                <div class="social-share-wrapper">
                    <p>Share</p>
                    <a href="https://twitter.com/intent/tweet?url=${shareDomain}/&text=I%20support%20Hope%20United&hashtags=HopeUnited" class="icon icon-twittter" target="_blank">
                            <span class="sr-only">Share on Twitter</span>
                    </a>
                    <a href="https://www.facebook.com/sharer/sharer.php?u=${shareDomain}%2F&amp;src=sdkpreparse" class="icon icon-facebook" target="_blank">
                            <span class="sr-only">Share on Facebook</span>
                    </a>
                </div>
            </div>
      	</div>`;
    }
}
