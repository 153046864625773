import CONSTANTS from './constants';

class SignUpForm {
    constructor(element) {
        this.element = element;
        this.inputValidationFields = this.element.querySelectorAll("[type='text'], [type='email']");
        this.optInCheckBoxes = this.element.querySelectorAll(".opt-in");
        this.submitCTA = this.element.querySelector("[type='submit']");
        this.validateInputField = this.validateInputField.bind(this);
        this.handleCheckBoxClick = this.handleCheckBoxClick.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.validateEmailField = this.validateEmailField.bind(this);
        this.validateInputField = this.validateInputField.bind(this);
    }

    async handleFormSubmit(e) {
        e.preventDefault();

        if(this.isFormValid()) {
            //Disbale submit button temporarly until form repsonse
            this.disableSubmitCTA();

            let fetchOption = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(this.getJsonPayload())
            }
            
            try {
                let response = await fetch(CONSTANTS.signUpEndPoint, fetchOption);
                let responseJSON = await response.json();
                if([200, 201].includes(response.status)) {
                    this.showFormSuccess(responseJSON);
                } else {
                    this.showErrorMessage(responseJSON.message);
                }
            } catch(e) {
                this.showErrorMessage('There is an Internal Server Error, please try again later');
            }finally {
                this.enableSubmitCTA();
            }
        }
    }

    clearErrorMessage() {
        const formSubmissionError = document.querySelector('.form-submission-error');
        if(formSubmissionError.classList.contains("d-none")) {
            document.querySelector('.alert-text').innerHTML = "";
            formSubmissionError.classList.add('d-none');
        }
    }

    showErrorMessage(msg) {
        document.querySelector('.alert-text').innerHTML = msg;
        document.querySelector('.form-submission-error').classList.remove('d-none');
    }

    enableSubmitCTA() {
        this.submitCTA.removeAttribute("disabled");
    }

    disableSubmitCTA() {
        this.submitCTA.setAttribute("disabled", "disabled");
    }

    showFormSuccess() {
        this.element.closest('.signup-form-wrapper').classList.add('d-none');
        document.querySelector('.js-signup-success').classList.remove('d-none');
    }

    getJsonPayload() {
        let jsonObject = {};
        this.inputValidationFields.forEach(ele => {
            jsonObject[ele.name] = ele.value;
        });

        jsonObject.optInPreferences = {};
        this.optInCheckBoxes.forEach(ele => {
            jsonObject.optInPreferences[ele.name] = ele.checked;
        });

        return jsonObject;
    }

    removeRequiredFromCheckBoxes() {
        this.optInCheckBoxes.forEach(ele => ele.classList.remove('required'));
    }

    addRequiredToCheckBoxes() {
        this.optInCheckBoxes.forEach(ele => ele.classList.add('required'));
    }

    isFormValid() {
        let invalidField = Array.from(this.inputValidationFields).filter((ele) => {
            return ele.name == 'email' ? !this.validateEmail(ele.value) : !ele.checkValidity();
        });
        
        let checkedOptins = this.checkBoxSelected();

        if(invalidField.length == 0 && checkedOptins.length > 0) {
            //The form is valid
            this.enableSubmitCTA();
            return true;
        }
        
        //For every other case if cta is enable disable it
        if(this.submitCTA.getAttribute("disabled") == undefined) {
            this.disableSubmitCTA();
        }
        
        //Highlights checkbox if all are empty
        if(checkedOptins.length == 0) {
            this.addRequiredToCheckBoxes();
        }
        
        return false;
    }

    checkBoxSelected() {
        let checkBox = Array.from(this.optInCheckBoxes).filter((elem) => elem.checked);
        return checkBox;
    }

    handleCheckBoxClick(e) {
        let selectedCheckBoxes = this.checkBoxSelected();
        if(selectedCheckBoxes.length == 0) {
            this.addRequiredToCheckBoxes();
        } else {
            this.removeRequiredFromCheckBoxes();
        }

        this.isFormValid();
    }

    validateEmail(value) {
        let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        console.log(emailRegex.test(value));
        return emailRegex.test(value);
    }

    validateEmailField(e) {
        let field = e.currentTarget;
        field.classList.add('invalid');

        if(field.checkValidity() && this.validateEmail(field.value)) {
            field.classList.remove('invalid');
        }

        this.isFormValid();
    }

    validateInputField(e) {
        let field = e.currentTarget;
        field.classList.add('invalid');
        field.value = field.value.trim().length == 0 ? field.value.trim() : field.value;
        if(field.checkValidity()) {
            field.classList.remove('invalid');
        }
        this.isFormValid();
    }

    bindEvents() {
        this.inputValidationFields.forEach(element => {
            let validatior = element.name == "email" ? this.validateEmailField : this.validateInputField;
            element.addEventListener('blur', validatior);
            element.addEventListener('keyup', validatior);
        });
        
        this.optInCheckBoxes.forEach(element => {
            element.addEventListener('click', this.handleCheckBoxClick);
        });

        this.element.addEventListener('submit', this.handleFormSubmit);
    }

    init() {
        this.bindEvents();
    }
}

window.addEventListener("DOMContentLoaded", () => { 
    let signUpForm = document.querySelector('.js-signup-form');
    if(signUpForm) {
        new SignUpForm(signUpForm).init();
    }
});