// import "@lottiefiles/lottie-player";

document.addEventListener("DOMContentLoaded", () => {
    const player = document.querySelector("lottie-player");

    if(player) {
        player.load(player.dataset.src);
        player.addEventListener("play", (e) => {
            document.getElementById('lottie-animation-placeholder').classList.add('d-none');
        });
    }
   
});