import CONSTANTS from './constants';

let searchObject = {};
let searchParams = window.location.search;

function searchParamsToObject() {
    return searchParams.substring(1).split("&").reduce(function(result, value) {
      var parts = value.split('=');
      if (parts[0]) result[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
      return result;
    }, {})
}

function showVerificationStatus(responseCode, responseMessage) {
    let verifyOverlay = document.querySelector('.verify-overlay');
    if(verifyOverlay) {
        if(responseCode == 200) {
            verifyOverlay.querySelector('.js-verify-success').classList.remove("d-none");
        } else {
            verifyOverlay.querySelector('.js-error-text').innerHTML = responseMessage;
            verifyOverlay.querySelector('.js-verify-error').classList.remove('d-none');
        }
    }

    //Todo: if dom is not ready may be use set timout to check 
    //Again the same method
}

const checkEmailVerification = async() => {
    if(searchParams.indexOf('verify') > -1) {
        searchObject = searchParamsToObject();
    
        if(searchObject.hasOwnProperty('email') && searchObject.hasOwnProperty('hash')) {
            let fetchOption = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify({email: searchObject.email, hash: searchObject.hash})
            };
            
            let response = {},
            responseJSON = {};

            try {
                response = await fetch(CONSTANTS.verfiyEndPoint, fetchOption);
                responseJSON = await response.json();
            }
            catch(err) {
                response.status = 500;
                responseJSON.message = "There is an Internal Server Error, please try again later."
            }
            finally {
                showVerificationStatus(response.status, responseJSON.message);
            }
        }
    }
}

checkEmailVerification();

window.addEventListener("DOMContentLoaded", () => { 
    if(searchObject.hasOwnProperty('overlay') && searchObject.overlay == "verify") {
        let overlay = document.querySelector('.overlay.verify-overlay');
        if(overlay) {
            overlay.classList.add('show');
		    document.querySelector('body').classList.add('overlay-open');
        }	
    }
});