import Cookie from 'js-cookie';
import GLOBALCONSTANTS from './constants'

const CONSTANTS = {
    "usercookie": 'bt_hope_user',
    'userconsentcookie': 'bt_hope_user_consent',
    "googlePostMetric": {
        "v": 1,                                         // Version
        "tid": "UA-184614607-3",                        // Tracking ID / Property ID.
        "cid": "557867075.1621602970",                  // Anonymous Client ID.
        "t": "event",                                   // Event hit type
        "ec": "cookie-consent",                         // Event Category. Required.
        "ea": "",                                       // Event Action. Required //cookie-name.
        "el": "",                                       // Event label. //consent-value
        "ev": "1",                                      // event value consent value accept = 1 or rejcet = 1
        "dl": window.location.href                      // traffic source.
    }
}

class ConsentManager {
    constructor() {
        this.consentOverlay = document.querySelector('.consent-overlay');
        this.handleConsentCTAClick = this.handleConsentCTAClick.bind(this);
        this.showConsentOverlay = this.showConsentOverlay.bind(this);
    }

    //Reference for JS unique ID
    //https://gist.github.com/gordonbrander/2230317#gistcomment-1618310
    uniqueID() {
        const chr4 = () => {
            return Math.random().toString(16).slice(-4);
        };

        return chr4() + chr4() +
            '-' + chr4() +
            '-' + chr4() +
            '-' + chr4() +
            '-' + chr4() + chr4() + chr4();
    }

    setConsentCookie(consentValue) {
        Cookie.set(CONSTANTS.userconsentcookie, consentValue, { expires: 365 * 10 });
    }

    setUserCookie() {
        if(this.getUserCookie() == undefined) {
            Cookie.set(CONSTANTS.usercookie, this.uniqueID(), { expires: 365 * 10 });
        }
    }

    getConsentCookie() {
        return Cookie.get(CONSTANTS.userconsentcookie);
    }

    getUserCookie() {
        return Cookie.get(CONSTANTS.usercookie);
    }

    showConsentOverlay() {
        this.consentOverlay.classList.add('show');
    }

    hideConsentOverlay() {
        this.consentOverlay.classList.remove('show');
    }

    hidePageSections(){
      let list = document.querySelectorAll(".cookie-consent-section");
      for(var item,i=list.length;i-- >0;){
          item = list[i];
          item.classList.add('d-none');
      }

    }

    loadConsentScripts() {
        let list = document.querySelectorAll(".consent-scripts");
        for(var item,i=list.length;i-- >0;){
            item = list[i];
            item.class = "trustecm_done";
            switch(item.nodeName.toLowerCase()){
                case "script":
                    var z = item.getAttribute("thesrc");
                    if(z){
                        var y = document.createElement("script");
                        y.src = z;
                        y.async = item.async;
                        item.parentNode.insertBefore(y,item);
                    }else {
                        var content = item.text || item.textContent || item.innerText;
                        eval(content);
                    }
                break;
                case "iframe":
                    var src = item.getAttribute("thesrc");
                    if(src) {
                        item.setAttribute("src", src);
                    }
                break;
                case "div":
                    var content = item.text || item.textContent || item.innerText;
                    eval(content);
            }
        }
    }

    postConsentDataToGA() {
        CONSTANTS.googlePostMetric.ea = this.getUserCookie();
        CONSTANTS.googlePostMetric.el = this.getConsentCookie();
        CONSTANTS.googlePostMetric.ev = CONSTANTS.googlePostMetric.el == 'accept' ? 1 : 0;

        fetch(GLOBALCONSTANTS.googlePostAPI, {
            method: 'post',
            body: new URLSearchParams(CONSTANTS.googlePostMetric)
        })
        .then(response => response.text())
        .then((data) => {
            try {
                data = JSON.parse(data);
                console.log(data);
            } catch(e) { }
        });
    }

    handleConsentCTAClick(e) {
        e.preventDefault();
        const consentValue = e.currentTarget.dataset.type;
        this.setUserCookie();
        this.setConsentCookie(consentValue);

        if (consentValue === 'accept') {
            this.loadConsentScripts();
        }

        if (consentValue === 'reject') {
          consentManager.hidePageSections();
        }

        this.hideConsentOverlay();
        this.postConsentDataToGA();

        //Event to fire that consent is giver
        document.dispatchEvent(new Event('ConsentCookieSet'));
    }

    bindConsentCTAEvents() {
        this.consentOverlay.querySelectorAll('.cookie-cta').forEach(element => {
            element.addEventListener('click', this.handleConsentCTAClick);
        });
    }

}

const consentManager = new ConsentManager();
export default consentManager;

document.addEventListener("DOMContentLoaded", () => {
    //Check we have overlay on page and if cookies are not set
    const cookieOverlay = document.querySelector('.consent-overlay');
    const userCookie = consentManager.getUserCookie();
    const cookiePage = document.querySelector('.cookie-policy');

    consentManager.bindConsentCTAEvents();

    if(cookieOverlay && userCookie == undefined) {
        //Do not show orverlay on cookie policy if no cookie present
        if(!cookiePage) {
            consentManager.showConsentOverlay();
        }
    }

    if(consentManager.getConsentCookie() === 'accept') {
        consentManager.loadConsentScripts();
    }
    if (consentManager.getConsentCookie() === 'reject') {
      consentManager.hidePageSections();
    }

    //Allow user to change cookie on manage cookie setting
    if(cookiePage) {
        cookiePage.querySelector('.js-cookie-settings').addEventListener('click', (e) => {
            e.preventDefault();
            consentManager.showConsentOverlay();
        });
    }
});
