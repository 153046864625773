const analyticHandler = (e) => {
    //e.preventDefault();
    if (typeof dataLayer !== 'undefined' && Array.isArray(dataLayer)) {
        let item = e.currentTarget;
        let analyticsEvent = item.dataset.analytics;
        //console.log(analyticsEvent);
        try {
            dataLayer.push(JSON.parse(analyticsEvent));
        } catch(e) {
            console.error("error pushin to analytics", e);
        }   
    }
}

const bindAnalyticEvents = () => {
    let analyticsItems = document.querySelectorAll('[data-analytics]');
    //console.log(analyticsItems);
    analyticsItems.forEach(ele => {
        ele.removeEventListener('click', analyticHandler);
        ele.addEventListener('click', analyticHandler);
    });
}

document.addEventListener("DOMContentLoaded", () => {
    bindAnalyticEvents();
});

export default bindAnalyticEvents;