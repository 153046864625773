import OverlayDataHandler from './overlay-data-handler';

export default class BTOverlay {
    constructor(element) {
        this.element = element;
		this.triggers = document.querySelectorAll(`[data-overlay="${element.dataset.overlayType}"]`);
		this.btnClose = element.querySelectorAll('.btn-close');
		this.returnToWebCTA = element.querySelectorAll('.js-return-to-webite');
		this.overlayRendered = this.overlayRendered.bind(this);
		this.showOverlay = this.showOverlay.bind(this);
		this.updateCloseBtnAnalytics = this.updateCloseBtnAnalytics.bind(this);
		this.closeOverlay = this.closeOverlay.bind(this);
		this.dataHandler = new OverlayDataHandler();
		this.lastFocusedElemnetOnPage;
		this.focusAbleElementsString = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
		this.focusElementList = [];
		this.init()
    }
	
	resetSignUpForm(overaly) {
		console.log(overaly);
		overaly.querySelector('.signup-form-wrapper').classList.remove('d-none');
		overaly.querySelector('.js-signup-success').classList.add('d-none');
		overaly.querySelector('.form-submission-error').classList.add('d-none');
		overaly.querySelector("[type='submit']").setAttribute("disabled", "disabled");

		let formFields = overaly.querySelectorAll('input');
		formFields.forEach(element => {
			if(element.classList.contains('floating-input')) {
				element.value = "";
				element.classList.remove('invalid')
			}

			if(element.classList.contains('opt-in')) {
				element.checked = false;
				element.classList.remove('required')
			}
		});
	}

	closeOverlay(e) {
		e.preventDefault();

		let overaly = e.currentTarget.closest('.overlay');
		if(overaly.classList.contains('signup-form')) {
			this.resetSignUpForm(overaly);
		}

		if(overaly.classList.contains('verify-overlay')) {
			//reset verify params received
			window.location.href = window.location.origin + window.location.pathname;
		}

		overaly.classList.remove('show', 'rendered');
		document.querySelector('body').classList.remove('overlay-open')
		this.dataHandler.clearOveralyData();
		this.removeFocusFromOverlay();
	}

	showOverlay(e) {
		e.preventDefault();
		this.setDataRenderer(e);
		this.updateCloseBtnAnalytics(e);
		const overlayName = e.currentTarget.dataset.overlay;
        const getSlideIndex = e.currentTarget.dataset.index;
        if (getSlideIndex && getSlideIndex !== 0) {
            window.swiperInstance.navigationIndex = parseInt(getSlideIndex);
        }

		if(overlayName) {
			let overlay = document.querySelector('.overlay.' + overlayName);
			overlay.classList.add('show');
			document.querySelector('body').classList.add('overlay-open');
		}

		//Remove once animation in place
		this.overlayRendered();
		this.setFocusWithInOveralay(e);
	}

	overlayRendered(e) {
		this.dataHandler.renderData();
		//this.element.classList.add('rendered');
	}

	setDataRenderer(e) {
		let {handler, url} = e.currentTarget.dataset;
    console.log("handler=>",handler);
    console.log("url=>",url);
		if(handler && url) {
			this.dataHandler.setData(url, handler);
		}
	}

	updateCloseBtnAnalytics(e) {
		let {handler} = e.currentTarget.dataset;
		if(handler) {
			let closeBtn = this.element.querySelector('.btn-close');
			try {
				let analyticObject = JSON.parse(closeBtn.dataset.analytics);
				analyticObject.eventAction = `overlay-${handler}`;
				closeBtn.dataset.analytics = JSON.stringify(analyticObject);
			} catch(e) {
				console.error('Error while updating analytics string', handler)
			}
		}
	}

	setFocusWithInOveralay(e) {
		var self = this;
		this.lastFocusedElemnetOnPage = e.currentTarget;
		this.focusElementList = this.element.querySelectorAll(this.focusAbleElementsString);
		this.focusElementList[0].focus();
		this.focusElementList[this.focusElementList.length - 1].addEventListener('focus', () => {
			console.log("focus on last element");
			self.focusElementList[0].focus();
		});
	}

	removeFocusFromOverlay() {
		this.lastFocusedElemnetOnPage.focus();
	}

    bindEvents() {
		this.btnClose.forEach(el => {
			el.addEventListener("click", this.closeOverlay);
		});

		this.triggers.forEach(el => {
			el.addEventListener("click", this.showOverlay);
		});

		this.returnToWebCTA.forEach(el => {
			el.addEventListener("click", this.closeOverlay);
		});

    	//TODO: Enable when bugs for animation are done
		//this.element.addEventListener('animationend', this.overlayRendered);

    }

    init() {
        this.bindEvents();
    }
}

window.addEventListener('DOMContentLoaded', (event) => {
	let overlays = document.querySelectorAll('.overlay');
    overlays.forEach(overlay => {
        new BTOverlay(overlay);
    });
});
