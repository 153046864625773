const OBSERVER_OPTIONS = {
  root: null,
  rootMargin: "0px",
  threshold: [...Array(100).keys()].map((x) => x / 100),
};
let MARQUEE_OBSERVE,
  SCROLLBAR_POSITION = 0;

function MARQUEE_CALLBACK(entries) {
  const element = entries[0];

  if (element.isIntersecting) {
    // console.log("inside");
    if (document.body.getBoundingClientRect().top > SCROLLBAR_POSITION) {
      // console.log("scroll reverse=");
      element.target.classList.remove("animate-reverse");
      element.target.classList.add("animate-forward");
    } else {
      // console.log("scroll down=");
      element.target.classList.remove("animate-forward");
      element.target.classList.add("animate-reverse");
    }
  } else {
    // console.log("outside");
    element.target.classList.remove("animate-reverse");
    element.target.classList.remove("animate-forward");
  }
  SCROLLBAR_POSITION = document.body.getBoundingClientRect().top;
}

document.addEventListener("DOMContentLoaded", () => {
  const marqueeBlock = document.querySelector(".marquee-block");
  if(marqueeBlock) {
    MARQUEE_OBSERVE = new IntersectionObserver(
      MARQUEE_CALLBACK,
      OBSERVER_OPTIONS
    );
    MARQUEE_OBSERVE.observe(marqueeBlock);
  }
  
});

