import consentManager from './consent-manager';
import {debounce} from './utils';

let bodyElement = document.querySelector('body');
let homeUnitedHeroLogo = document.querySelector('.hero-logo');
let heroLogoWrapper = document.querySelector('.hero-logo-wrapper');
let homeUnitedHeroLogoPos = homeUnitedHeroLogo ? homeUnitedHeroLogo.getBoundingClientRect() : 0;

let parallaxContainer = document.querySelector('.full-team-shot');
let parallaxSibling = parallaxContainer ? parallaxContainer.nextElementSibling : undefined;

let fullTeamParallaxContent = () => {
    let parallaxContainerHeight = parallaxContainer.getBoundingClientRect().height;
    let parallaxSiblingTop = parallaxSibling.getBoundingClientRect().top;
    let distaceFromTop = window.innerHeight + parallaxContainerHeight;

    if(parallaxSiblingTop > (distaceFromTop - parallaxContainerHeight)) {
        parallaxContainer.querySelector('.content-container').removeAttribute('style');
    }
    
    if(parallaxSiblingTop < (distaceFromTop - (parallaxContainerHeight/1.5))) {
        parallaxContainer.querySelector('.content-container').style.transform = 'translateY(0)';
        return;
    }

    if(parallaxSiblingTop < (distaceFromTop - (parallaxContainerHeight/2))) {
        parallaxContainer.querySelector('.content-container').style.transform = 'translateY(50%)';
        return;
    }

    if(parallaxSiblingTop < (distaceFromTop - (parallaxContainerHeight/2.5))) {
        parallaxContainer.querySelector('.content-container').style.transform = 'translateY(100%)';
        return;
    }
}

let scrollHandler = () => {
    const herpLogoWrapperPosY = heroLogoWrapper ? heroLogoWrapper.getBoundingClientRect().top : 0;
    
    if (herpLogoWrapperPosY < -(homeUnitedHeroLogoPos.height / 2)) {
        bodyElement.classList.add('is-fixed')
    } else {
        bodyElement.classList.remove('is-fixed')
    }

    if(parallaxContainer && parallaxSibling) {
        fullTeamParallaxContent();
    }
}



let showHeroTransition = () => {
    bodyElement.classList.remove('home-preload');
    bodyElement.classList.add('consent-set');    
}

let startHeroSectionAnimation = () => {
    let maxContainer = document.querySelector('.hero-section').querySelector('.max-container');
    maxContainer.classList.add('start-animation');
}

document.addEventListener("DOMContentLoaded", () => {
    //Only for home page
    if(bodyElement.classList.contains('home-preload')) {
        const userCookie = consentManager.getUserCookie();
        if(userCookie != undefined) {
            showHeroTransition();
        }

        //Scroll listener
        window.addEventListener("scroll", debounce(scrollHandler, 10));

        //CookieConsentSet
        document.addEventListener('ConsentCookieSet', showHeroTransition);

        //hero transition end
        let heroImageSection = document.querySelector('.hero-section').querySelector('.left-section .content-section');
        heroImageSection.addEventListener('transitionend', startHeroSectionAnimation);
    }

    //Replace currentPageURL for static social share buttons
    document.querySelectorAll('.js-social-share').forEach((icon) => {
        let shareURL = icon.getAttribute("href");
        shareURL = shareURL.replace('${currentPageURL}', window.location.href);
        icon.setAttribute("href", shareURL);
    });
});