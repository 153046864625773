const CONSTANTS = {
    dev: {
        signUpEndPoint: "https://api.bthopeunited-dev.hst.digitas.cloud/signup",
        verfiyEndPoint: "https://api.bthopeunited-dev.hst.digitas.cloud/verification",
        googlePostAPI: "https://www.google-analytics.com/debug/collect"
    },
    qa: {
        signUpEndPoint: "https://api.bthopeunited-qa.hst.digitas.cloud/signup",
        verfiyEndPoint: "https://api.bthopeunited-qa.hst.digitas.cloud/verification",
        googlePostAPI: "https://www.google-analytics.com/debug/collect"
    },
    prod: {
        signUpEndPoint: "https://api.bthopeunited.com/signup",
        verfiyEndPoint: "https://api.bthopeunited.com/verification",
        googlePostAPI: "https://www.google-analytics.com/collect"
    }
}
const getConstants = () => {
    let builEnv = document.getElementById("build-env");
    builEnv = builEnv && builEnv.value ? builEnv.value : "prod";

    return CONSTANTS[builEnv];
};

export default getConstants();